import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgoService } from 'src/app/shared/services/ngo/ngo.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';
import { CommonSharedServiceService } from 'src/app/shared/shared-services/common-shared-service/common-shared-service.service';
import { ProjectService } from 'src/app/shared/services/project/project.service';
import ValidationEngine from 'devextreme/ui/validation_engine';
import menu from './menu.json';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard-menu',
  templateUrl: './dashboard-menu.component.html',
  styleUrl: './dashboard-menu.component.scss',
})
export class DashboardMenuComponent implements OnInit {
  public menuItems: any[];
  public activeIndex: number;
  public activeSubmenuIndex: number;
  public menuOpen: boolean = true;
  public open = new Map<number, boolean>();
  public popupVisible = false;
  public projectList: any = [];
  public selectedProjectId: any = null;
  public isOpen = false;
  private targetRoute: string;
  public selectedNgo: any = null;
  public sessionUser: any;
  public userNgoList: any = [];
  private authSubscription!: Subscription;

  constructor(
    private readonly router: Router,
    private readonly ngoService: NgoService,
    private readonly authService: AuthService,
    public readonly projectService: ProjectService,
    private readonly utilitiesService: UtilitiesService,
    public readonly localStorageService: LocalStorageService,
    private readonly commonSharedService: CommonSharedServiceService
  ) {
    this.menuItems = menu;
    this.sessionUser = this.localStorageService.getSessionUser();
    this.ngoService
      .getNgoByUserId(this.sessionUser?.id)
      .subscribe((result: any) => {
        this.userNgoList = result.filter(
          (user) => user.approval_status === 'ACCEPTED'
        );

        if (this.userNgoList?.length === 0) {
          this.menuItems.forEach((item: any) => {
            if (item.name === 'Manage') {
              item.submenu = [];
            }
          });
        }

        this.menuItems.forEach((item: any) => {
          if (item.submenu?.length > 0) {
            this.open.set(item.index, false);
          }
        });
        this.getProjectsByNgoID();
        this.commonSharedService.ngoSelectionAction$.subscribe((action) => {
          this.getProjectsByNgoID();
        });
      });
  }

  public ngOnInit(value?: any): void {
    const aclMenu = value || this.getACLFromUrl();
    this.activeIndex = aclMenu?.menu_index;
    this.activeSubmenuIndex = this.getActiveSubmenuIndex();
    this.selectedNgo = this.localStorageService.getNgoInLocalStorage() || null;

    this.authSubscription = this.authService.authState$.subscribe();
    this.commonSharedService.ngoProfile$.subscribe(() => {
      this.getNgoByUserId();
    });
  }

  public getNgoByUserId(): void {
    this.sessionUser = this.localStorageService.getSessionUser();
    this.ngoService
      .getNgoByUserId(this.sessionUser?.id)
      .subscribe((result: any) => {
        this.userNgoList = result;

        if (this.selectedNgo) {
          const index = result.findIndex(
            (item) => item.id === this.selectedNgo.id
          );
          this.selectedNgo = result[index];

          this.localStorageService.setNgoInLocalStorage(this.selectedNgo);
        }
      });
  }

  private getProjectsByNgoID(): void {
    if (this.selectedNgo) {
      this.projectList = [];
      this.utilitiesService.showSpinner(true);
      this.projectService.getProjectsByNgoId(this.selectedNgo?.id).subscribe({
        next: (res) => {
          this.projectList = res;
          this.utilitiesService.showSpinner(false);
        },
        error: (err) => {
          this.utilitiesService.showSpinner(false);
        },
      });
    } else if (this.userNgoList?.length > 0) {
      this.utilitiesService.showSwalAndReturn({
        type: 'warning',
        title: `You must select an NGO first!`,
        confirmButtonText: 'OK',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
      });
    }
  }

  public isActive(index: number): boolean {
    return this.activeIndex === index;
  }

  public form() {
    const { isValid } = ValidationEngine.validateGroup('selectValidationGrp');
    if (isValid) {
      if (this.selectedProjectId) {
        const selectedProject = this.projectList.find(
          (project) => project.id === this.selectedProjectId
        );
        if (selectedProject) {
          this.localStorageService.setProjectInLocalStorage(selectedProject);
          this.closeModal();
          this.router.navigate([this.targetRoute]);
        }
      }
    } else {
      const selectedProject = this.projectList.find(
        (project) => project.id === this.selectedProjectId
      );
      this.localStorageService.setProjectInLocalStorage(selectedProject);
      this.closeModal();
      this.router.navigate([this.targetRoute]);
    }
  }

  public closeModal(): void {
    this.popupVisible = false;
  }

  public submenuClicked(router: string): void {
    if (
      this.projectList?.length === 0 ||
      !this.localStorageService.getProjectFromLocalStorage()
    ) {
      this.targetRoute = router;
      this.popupVisible = true;
    }
    if (
      this.projectList?.length > 0 &&
      this.localStorageService.getProjectFromLocalStorage()
    ) {
      this.router.navigate([router]);
    }
  }

  public selectOrganization(ngo: any) {
    this.isOpen = false;
    if (!(JSON.stringify(this.selectedNgo) === JSON.stringify(ngo))) {
      this.selectedNgo = ngo;
      this.localStorageService.setNgoInLocalStorage(ngo);
      this.localStorageService.deleteProjectFromLocalStorage();
      this.triggerNgoSelection();
    }
  }

  private triggerNgoSelection() {
    this.commonSharedService.ngoSelected();
  }

  public toggleMenu(item: any): void {
    if (this.open.has(item?.index)) {
      this.toggleOpen(item?.index);
    }
    if (item?.index === 4 && this.userNgoList?.length === 0) {
      localStorage.removeItem('subMenu_index');
      this.router.navigate(['/dashboard/manage/my-organization']);
    } else if (item?.index === 4 && this.userNgoList?.length > 0) {
      // if (!this.localStorageService.getNgoInLocalStorage()) {
      //   this.utilitiesService.showSwal('warning', 'Select an NGO first.');
      // }
    }
  }

  private toggleOpen(index: number): void {
    const value = this.open.get(index);
    this.open.set(index, !value);
  }

  private handleNavBarOpen(index: number, className: string): void {
    // this.open[index] = true;
    // var myCollapse = document.getElementById(className);
    // myC
    // ollapse.classList.add('show');
  }

  private getACLFromUrl(): any {
    const value = this.router.url;
    if (value === '/dashboard') {
      return { menu_index: 1 };
    } else if (value.includes('dashboard/learn')) {
      this.handleNavBarOpen(2, 'submenu2');
      return { menu_index: 2 };
    } else if (value.includes('dashboard/donate')) {
      this.handleNavBarOpen(3, 'submenu3');
      return { menu_index: 3 };
    } else if (value.includes('dashboard/manage')) {
      this.handleNavBarOpen(4, 'submenu4');
      return { menu_index: 4 };
    } else if (value.includes('/dashboard/donor-portal')) {
      this.handleNavBarOpen(5, 'submenu5');
      return { menu_index: 5 };
    } else if (value.includes('/dashboard/donor-crm')) {
      this.handleNavBarOpen(6, 'submenu6');
      return { menu_index: 6 };
    } else {
      return null;
    }
  }

  public setActiveSubmenuIndex(menuIndex: number, subMenuIndex: number): void {
    this.activeIndex = menuIndex;
    this.activeSubmenuIndex = subMenuIndex;
    localStorage.setItem('subMenu_index', subMenuIndex.toString());
  }

  public getActiveSubmenuIndex(): number {
    return +localStorage.getItem('subMenu_index');
  }

  public routeToNgoProfilePage(ngo_id: string): void {
    const encryptedParams = this.localStorageService.encryptObjectUrl({
      id: ngo_id,
    });
    this.router.navigate(['/dashboard/manage/ngo-profile'], {
      queryParams: { data: encryptedParams },
    });
  }

  ngOnDestroy() {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }
}
